* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 60px;
}

.ui.navbar {
  margin-top: 20px !important;
}

.padding-reset {
  padding: 0px !important;
}

.ui.message {
  padding: 40px !important;
}

@media (max-width: 767px) {
  .ui.inverted.navbar {
  }
}

.ui.vertical.menu {
  margin-top: -15px !important;
  width: 100%;
}

// .bar {
//   display: flex;
//   align-items: center;
//   width: 100%;
//   background: #eee;
//   padding: 20px;
//   margin: 0 0 20px 0;
//   > * {
//     margin: 0 10px;
//   }
//   .nav-item {
//     font-size: 1.1em;
//     font-weight: 600;
//     a.main-nav {
//       color: #6b6b6b;
//       text-decoration: none;
//       &.active,
//       &:hover {
//         font-weight: 600;
//         color: #000;
//       }
//     }
//   }
// }
// .search {
//   flex: 1;
//   input {
//     width: 100%;
//   }
// }

// .no-flexbox {
//   .bar {
//     display: table;
//     border-spacing: 15px;
//     padding: 0;
//   }
//   .bar > * {
//     display: table-cell;
//     vertical-align: middle;
//     white-space: nowrap;
//   }
//   .nav-item {
//     width: 1px;
//   }
// }

// @media (max-width: 650px) {
//   .bar {
//     flex-wrap: wrap;
//   }
//   .nav-item {
//     width: 100%;
//     margin: 15px;
//   }
//   .search {
//     width: 100%;
//   }
// }
