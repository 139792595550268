.ui.button {
  &.primary,
  &.negative {
    margin-top: 1.6em;
  }
}

.two.fields {
  position: relative;
}

/* starting state of animation */
.fade-enter {
  right: 200px;
  opacity: 0;
  max-height: 0;
  transform: scaleY(0);
}
/* end state of animation */
.fade-enter-active {
  right: 0px;
  opacity: 1;
  max-height: initial;
  transform: scaleY(1);
  transition: 0.3s ease-in all;
}
.fade-leave {
  opacity: 1;
  right: 0px;
}

.fade-leave-active {
  opacity: 0;
  right: -100px;
  transition: 0.3s ease-in all;
}
