.search-history {
  font-weight: 600;
}
.clickable {
  cursor: pointer;
  color: #383838;
  font-weight: 400;
  &:hover {
    font-weight: 600;
    color: #000000;
  }
}
